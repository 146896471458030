import { getSfProduct } from '~/lib/shopify/product/product-by-id'

import { GID, UnlikelyProduct } from '@unlikelystudio/commerce-connector'

type GetProductByIdParams = {
  id: GID
  withInventory?: boolean
}

export async function getProductById({
  id,
  withInventory = false,
}: GetProductByIdParams) {
  try {
    const node = await getSfProduct({
      id,
      includeQuantityAvailable: withInventory,
    })

    if (!node) return null

    return node as UnlikelyProduct
  } catch (error) {
    return null
  }
}
